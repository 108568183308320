body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.chat-container {
  height: calc(100vh - 175px);
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 999;
}

.mobile-nav-close {
  margin-right: 0;
  margin-left: 0;
  bottom: 60px;
  left: calc(50% - 38px);
}

/** UI Theming overrides **/
.bg-indigo-600 {
  background-color: #5c62ff !important;
}

.bg-indigo-500 {
  background-color: #7c80f1 !important;
}

.text-indigo-700 {
  color: #6469f4 !important;
}

.bg-green-100 {
  background-color: #c1efe4 !important;
}

.text-green-600 {
}

.text-page {
  margin: 2rem auto 0;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.text-page > section,
.text-page > details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.text-page > details {
  background-color: white;
  border-radius: 0.5rem;
  color: #555;
}

.text-page summary + * {
  border-top: thin solid #ccc;
  padding-top: 1rem;
}

.text-page h1 {
  font-size: x-large;
  font-weight: bold;
}

.text-page h2 {
  display: inline-block;
  font-size: large;
  font-weight: bold;
}

.text-page h3 {
  font-weight: 500;
  margin-top: 1rem;
}

.text-page ol {
  list-style-type: decimal;
  padding-left: 2rem;
}

.text-page ul {
  list-style-type: disc;
  padding-left: 2rem;
}

.text-page svg {
  display: inline;
  height: 1.5rem;
  vertical-align: text-bottom;
}

*[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
